body{
    padding: 0;
}
.leaflet-container{
    background-color: aliceblue;
    width: 100%;
    height: 100vh;
}
.leaflet-popup-content-wrapper .leaflet-popup-content {
    font-size: .8rem !important;
}
/* .grid-container {
    display:grid;
    grid-template-columns: 35px 1fr;
} */
.blink_me {
    font-size: .6rem;
    background-color: burlywood;
  }
