.bin {
    margin-top: .5rem;
    display: grid;
    grid-template-columns: auto auto ;
    grid-gap: .5rem;
    justify-items: start;
    
  }
  .span {
    grid-column: span 2;
  }
  .stretch {
      justify-self: stretch;
      
  }
  .center {
    justify-self:center;
  }

  .inputProp {
    font-size: 15;
     height: 25;
     width: "auto";
  }
  
  .textField {
      font-size: .5em;
  }