.side-drawer {
    height:100vh;
    background: white;
    box-shadow: 1px 0px 3px rgba(0,0,0,.5);
    position:fixed;
    top:0;
    left:35px;
    width:80%;
    max-width: 400px;
    z-index: 1001;
    transform: translateX(-115%);
    transition: transform .1s ease-in-out;
    overflow-y: auto;
   
}
.side-drawer.open {
     transform: translateX(0);
}

.titleContainer {
    background-color: rgb(202, 223, 247);
    margin-bottom: 10px;
}
.title {
    margin:0;
   /* border: 3px solid black; */
   display: inline-block;
 
}
.chev {
    margin: 2px 2px;
    float: right;
    font-size: 1.25rem;
    /* border: 3px solid black; */
}
