.flex-container {
    padding: 0;
    margin:0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap:.5rem;
}
