.sidebar {
    padding: 0;
    margin:0;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width:100%;
   
}

.img {
    padding: 5px;
    height: 25px;
    width: 25px;

}


.flex-item {
    font-size: 1.25rem;
    margin: 10px;
}
.flex-item:hover,
.flex-item::selection {
    background-color: lightblue;
}

.flex-end {
   margin-top: auto;
}