/* .asset {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
 } */

/* .asset * {
   margin-bottom: 5px;
 } */

 .asset {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 1rem;

    justify-items: start;
  }
  .span {
    grid-column: span 2;
  }
  .stretch {
      justify-self: stretch;
      
  }
  .center {
    justify-self:center;
  }